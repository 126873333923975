import * as React from 'react'
import logotype from '../../img/logotype-optim.webp'
import mic from '../../img/mic-chopped.webp'
import noise from '../../img/noise.webp'
import {grain} from '../../styles/grain.module.css'
import {motion} from 'framer-motion'
import {CarouselPage} from '../Carousel'

const expoEase = [0, 0.71, 0.2, 1.01]

const Slogans = [
  'Make\nNoise\nTogether',
]

const Hero = () => {
  const content = React.useMemo(() => {
    return Slogans[Math.floor(Math.random() * Slogans.length)]
  }, [])

  return <CarouselPage className="bg-[#111] text-[#ededed] relative overflow-clip">
    {/*Radial Gradient*/}
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{delay: 0.6, duration: 1.6, ease: expoEase}}
    >
      <div
        className="w-screen h-[175vh] absolute left-0 bottom-[-75vh] z-0"
        style={{
          background: 'radial-gradient(circle, rgba(255,0,0,0.25) 0%, rgba(17,17,17,0.25) 75%)'
        }}
      />
    </motion.div>

    {/*Mic*/}
    <motion.div
      className="flex justify-center w-screen h-screen bottom-0 absolute z-10"
      initial={{y: '5vh', opacity: 0}}
      animate={{y: '0', opacity: 1}}
      transition={{delay: 0.2, duration: 0.1, ease: expoEase}}
    >
      <img className="absolute bottom-0 max-w-md" src={mic} alt="Microphone"/>
    </motion.div>

    {/*Grain*/}
    <div className="z-20 absolute w-screen h-screen overflow-clip opacity-60">
      <div
        className={'w-[300vw] h-[300vh] ' + grain}
        style={{background: `url(${noise})`}}
      />
    </div>

    {/*Content*/}
    <div className="flex flex-col h-full w-screen justify-between z-30 top-0 absolute">
      {/*Logotype*/}
      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.2, duration: 0.6, ease: expoEase}}
      >
        <div className="flex max-w-screen justify-center">
          <div className="m-12">
            <img className="max-w-full sm:max-w-sm" src={logotype} alt="Overplayed Records"/>
          </div>
        </div>
      </motion.div>

      {/*Text + Button*/}
      <div className="flex justify-center">
        <div className="max-w-md w-full text-center">
          <motion.div
            initial={{y: '10vh', opacity: 0}}
            animate={{y: '0', opacity: 1}}
            transition={{delay: 0.4, duration: 0.6, ease: expoEase}}
            className="mb-20">

            {/*Text*/}
            <div>
              <h1
                style={{textShadow: "#000 0 0 1em"}}
                className="font-display font-semibold leading-[.9em] text-6xl whitespace-pre">
                {content}
              </h1>

              <p className="font-display text-xl mt-8">
                <br/>
              </p>
            </div>

            {/*Button*/}
            <motion.div
              initial={{y: '10vh', opacity: 0}}
              animate={{y: '0', opacity: 1}}
              transition={{delay: 0.4, duration: 0.6, ease: expoEase}}
            >
              <motion.button
                style={{fontVariationSettings: '"wght" 400'}}
                onClick={() => {window.open("https://instagram.com/ovrplyd", "_blank")}}
                whileHover={{fontVariationSettings: '"wght" 700', transition: {ease: expoEase}}}
                className="bg-black px-6 py-3 text-2xl my-8 font-display rounded-lg">
                stay in the know
              </motion.button>
            </motion.div>
          </motion.div>
        </div>
      </div>

    </div>
  </CarouselPage>
}

export {Hero}
