import * as React from 'react'

export const Carousel = ({children}) => (
  <div className="h-screen overflow-y-scroll">
    {children}
  </div>
)

export const CarouselPage = ({children, screenHeight, className}) => {
  const classes = React.useMemo(() => {
    if (screenHeight === false)
      return className
    return 'h-screen ' + className
  }, [screenHeight, className])

  return <div className={classes}>
    {children}
  </div>
}

export const DoubleCarouselPage = ({children, screenHeight, className}) => {
  const classes = React.useMemo(() => {
    if (screenHeight === false)
      return className
    return 'h-[200vh] md:h-screen ' + className
  }, [screenHeight, className])

  return <div className={classes}>
    {children}
  </div>
}
