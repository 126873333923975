import * as React from 'react'
import {CarouselPage} from '../Carousel'
import StrokeLogo from '../../img/stroke.webp'


const Footer = () => {
  return <CarouselPage
    screenHeight={false}
    className="bg-black text-[#ededed] font-display text-lg overflow-clip"
  >
    <footer className="h-full w-screen py-8 px-8 sm:px-12 flex justify-between items-stretch">
      <div>
        © {(new Date(Date.now())).getFullYear()} Overplayed Records<br/><br/>
        <a className="hover:underline" href="mailto:hello@ovrplyd.com">hello@ovrplyd.com</a><br/>
        <a className="hover:underline" href="https://instagram.com/ovrplyd/">@ovrplyd</a><br/>
      </div>
      <div className="hidden lg:flex items-center">
        <img className="max-w-[33vw]" src={StrokeLogo}/>
      </div>
    </footer>
  </CarouselPage>
}


export default Footer
