import * as React from 'react'
import {Carousel} from '../components/Carousel'
import {Hero} from '../components/hero/Hero'
import Vision from '../components/vision/page'
import Footer from '../components/footer/footer'

const IndexPage = () => {
  return <Carousel>
    <Hero/>
    <Vision/>
    <Footer/>
  </Carousel>
}

export function Head() {
  return (
    <title>Overplayed Records</title>
  )
}

export default IndexPage
