import * as React from 'react'
import {DoubleCarouselPage} from '../Carousel'
import {motion, useMotionValue, useTime, useTransform} from 'framer-motion'
import MixMic from '../../img/mixmic.png'
import {grain} from '../../styles/grain.module.css'
import noise from '../../img/noise.webp'

const expoEase = [0, 0.71, 0.2, 1.01]

const Gramophone = ({text}) => {
  text += ' ​'
  const r = 115
  const cx = r / 2 + 300
  const cy = r / 2 + 680

  const path = `M ${(cx - r)}, ${cy}
      a ${r},${r} 0 1,0 ${(r * 2)},0
      a ${r},${r} 0 1,0 -${(r * 2)},0`

  const textPathRef = React.useRef(undefined)

  React.useEffect(() => {
    const length = textPathRef.current.getComputedTextLength()
    const expectedLength = 2 * Math.PI * r
    let spaceChars = text.split(' ').length - 1
    const spaceExtension = (expectedLength - length) / (spaceChars)
    textPathRef.current.style.wordSpacing = `${spaceExtension}px`
  }, [text])

  const time = useTime()
  const rotation = useMotionValue(0)
  const durationScale = useMotionValue(1)

  React.useEffect(() => {
    time.onChange(
      v => rotation.set((rotation.get() + (v - time.getPrevious()) * 0.022 * durationScale.get()) % 360)
    )
  })

  return <div className="h-screen flex items-center flex-1">
    <div className="flex-1 h-full w-full p-4 flex justify-center items-center">
      <svg
        className="max-h-full max-w-full p-8"
        viewBox="0 0 571 917" xmlns="http://www.w3.org/2000/svg"
      >
        <image href={MixMic} height="817" width="571"/>
        <defs>
          <path id="circle" d={path}/>
        </defs>
        <motion.text
          style={{rotate: rotation}}
          onHoverStart={() => durationScale.set(-1.05)}
          onHoverEnd={() => durationScale.set(1)}
          className="font-display text-4xl cursor-progress"
        >
          <textPath fill="#ededed" ref={textPathRef} xlinkHref="#circle">
            {text}
          </textPath>
        </motion.text>
      </svg>
    </div>
  </div>
}

const Text = () => {
  return <div className="h-screen flex items-center flex-1">
    <div className="m-8 sm:m-12 sm:mr-16 w-full">
      {/*<h1 className="font-display font-semibold leading-[.9em] tracking-tighter text-4xl sm:text-6xl">*/}
      {/*  HEADING?*/}
      {/*</h1>*/}

      <p className="font-display text-xl mt-8 max-w-lg">
        <p className="text-3xl pb-4">OVRPLYD is a Bangalore based music&nbsp;distribution and entertainment company.</p>
        <p className="pb-3">We challenge the norms of a traditional label—we put the artist first, and take no cut out
          of their
          earnings.</p>
        <p>We strive to redefine what it means to be a signed artist by unifying Bangalore music and prioritising
          collaboration of talents and creating a community of the best Bangalore has to offer.</p>
      </p>
    </div>
  </div>
}

const Page = () => {
  return <DoubleCarouselPage className="bg-[#b3241a] text-[#ededed] relative overflow-clip">
    {/*Grain*/}
    {/*<div className="z-20 absolute w-screen h-screen overflow-clip opacity-60">*/}
    {/*  <div*/}
    {/*    className={'w-[300vw] h-[300vh] ' + grain}*/}
    {/*    style={{background: `url(${noise})`}}*/}
    {/*  />*/}
    {/*</div>*/}

    <div className="flex flex-col md:flex-row h-screen w-screen">

      {/*Text*/}
      <div className="order-2 md:order-1 flex-[3] lg:flex-1 flex">
        <Text/>
      </div>

      {/*Gramophone*/}
      <div className="bg-black order-1 md:order-2 flex-[2] lg:flex-1 flex relative">
        {/*size div*/}
        <div className="h-screen"/>

        {/*gramophone w circle text*/}
        <div className="w-full h-full absolute left-0 top-0 z-10 select-none">
          <Gramophone text={'can I have some records please?'}/>
        </div>

        {/*gradients*/}
        <div className="w-full h-full absolute relative left-0 top-0 z-0 overflow-clip">
          <motion.div
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            transition={{delay: 1.3, duration: 1.6, ease: expoEase}}
            className="w-[150%] h-[150%] left-[-40%] top-[-40%] absolute z-0"
            style={{
              background: 'radial-gradient(circle, rgba(255,0,0,0.25) 0%, rgba(17,17,17,0.25) 50%)'
            }}
          />
          <motion.div
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            transition={{delay: 1.3, duration: 1.6, ease: expoEase}}
            className="w-[150%] h-[150%] absolute z-0"
            style={{
              background: 'radial-gradient(circle, rgba(255,0,0,0.25) 0%, rgba(17,17,17,0.25) 50%)'
            }}
          />
        </div>

        {/*Grain*/}
        <div className="z-20 absolute w-full h-full overflow-clip opacity-60 pointer-events-none">
          <div
            className={'w-[300vw] h-[300vh] ' + grain}
            style={{background: `url(${noise})`}}
          />
        </div>

      </div>
    </div>
  </DoubleCarouselPage>
}

export default Page
